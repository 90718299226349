const checkoutFragment = `
fragment CheckoutInfo on Checkout {
        id
        completedAt
        currencyCode
        subtotalPriceV2 {
          amount
          currencyCode
        }
        buyerIdentity {
          countryCode
        }
        totalPriceV2 {
          amount
          currencyCode
        }
        webUrl
        lineItems(first: 10) {
          edges {
            node {
              id
              quantity
              title
              variant {
                id
                title
                priceV2 {
                  amount
                }
                product {
                  images(first: 1) {
                    edges {
                      node {
                        altText
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
}

`
export const createCheckout = ` 
  mutation  CreateCheckout($input: CheckoutCreateInput!, $country: CountryCode) @inContext(country: $country) {
    checkoutCreate(input: $input) {
      checkout {
        ...CheckoutInfo
      }
    }
  }
  ${checkoutFragment}
`

export const getCheckout = `
  query GetCheckout($checkoutId: ID!, $country: CountryCode) @inContext(country: $country) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...CheckoutInfo
      }
    }
  }
  ${checkoutFragment}
`

export const getProduct = `
  query GetProduct($id: ID!, $country: CountryCode) @inContext(country: $country){
    product(id: $id) {
      id
      title
      variants(first: 1) {
        edges {
          node {
            id
            title
            price {
              amount
              currencyCode
            }
            priceV2 {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`

export const addItem = `
  mutation AddItem($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!, $country: CountryCode) @inContext(country: $country) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutInfo
      }
    }
  }
  ${checkoutFragment}
`

export const removeItem = `
  mutation RemoveItem($checkoutId: ID!, $lineItemIds: [ID!]!, $country: CountryCode) @inContext(country: $country) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      checkout {
        ...CheckoutInfo
      }
    }
  }
  ${checkoutFragment}
`

export const updateItem = `
  mutation UpdateItem($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...CheckoutInfo
      }
    }
  }
  ${checkoutFragment}
`
