const title = ''
const description = ''

const SEO = {
  title,
  description,
  canonical: '',
  openGraph: {
    type: 'website',
    locale: 'en_CA',
    url: '',
    title,
    description,
    images: [
      {
        url: '',
        alt: title,
        width: 1280,
        height: 720
      }
    ]
  }
}

export default SEO
