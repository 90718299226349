import { loadIntercom } from 'next-intercom'

export default function Intercom() {
  const intercomAppId = process.env.INTERCOM_APP_ID

  if (intercomAppId) {
    loadIntercom({
      appId: intercomAppId,
      name: null,
      delay: 100
    })
  }

  return null
}
