export const formatPrice = (price: number) =>
  new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD'
  }).format(price)

export const getItemFromLocalStorage = (key: string) =>
  JSON.parse(window.localStorage.getItem(key))

export const setItemToLocalStorage = (key: string, value: any) =>
  window.localStorage.setItem(key, JSON.stringify(value))
