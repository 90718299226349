import App from 'next/app'
import { withRouter, NextRouter } from 'next/router'
import { MDXProvider } from '@mdx-js/react'
import { DefaultSeo } from 'next-seo'
import Head from 'next/head'
import SEO from '~/../next-seo.config'
import '~/styles/fonts.css'
import '@fontsource/merriweather'
import '~/styles/tailwind.css'
import '~/styles/app.css'
import '~/styles/tailwind-utils.css'

import { ShopifyContextProvider } from '~/context/ShopifyContext'
import Intercom from '~/components/Intercom'
import Analytics from '~/components/Analytics'
import * as gtag from '~/lib/gtag'

interface WithRouterProps {
  router: NextRouter
}

interface MaryAGAppProps extends WithRouterProps {}

class MaryAGApp extends App<MaryAGAppProps> {
  componentDidMount() {
    const router = this.props.router
    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }
  render() {
    const { Component, pageProps } = this.props

    return (
      <MDXProvider>
        <ShopifyContextProvider>
          <Head>
            <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
            <meta
              name="facebook-domain-verification"
              content="skwdufp1j6i2l2j8pjj2od4pf9d8qk"
            />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta content="#ffffff" name="theme-color" />
            <meta content="#ffffff" name="msapplication-TileColor" />
          </Head>
          <DefaultSeo {...SEO} />
          <Component {...pageProps} />
          <Intercom />
          <Analytics />
        </ShopifyContextProvider>
      </MDXProvider>
    )
  }
}

export default withRouter(MaryAGApp)
