import Script from 'next/script'
import { Fragment } from 'react'

import { GA_TRACKING_ID, GA_TAG_ID } from '~/lib/gtag'

export default function Analyics() {
  return (
    <Fragment>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      {GA_TRACKING_ID && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
          <Script
            strategy="afterInteractive"
            id="ga-datalayer"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `
            }}
          />
        </>
      )}
      {/* Global Tag Manager */}
      {GA_TAG_ID && (
        <>
          <Script
            strategy="afterInteractive"
            id="ga-tag-manager"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GA_TAG_ID}');
          `
            }}
          />
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${GA_TAG_ID}`}
              height="0"
              width="0"
              style={{
                display: 'none',
                visibility: 'hidden'
              }}
            />
          </noscript>
        </>
      )}
    </Fragment>
  )
}
