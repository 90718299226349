import { useMemo, createContext, useEffect, useReducer, ReactNode } from 'react'

import { sendShopifyStorefrontRequest } from '~/lib/shopify'
import {
  createCheckout,
  getCheckout,
  addItem,
  removeItem,
  updateItem
} from '~/lib/graphql'
import {
  getItemFromLocalStorage,
  setItemToLocalStorage
} from '~/utils/functions'

export const ShopifyContext = createContext(null)

const getQuantity = (lineItems: any, op: number) => (id: string) => {
  const q = lineItems.edges.find(({ node }) => node.id === id)?.node.quantity
  if (!q) {
    return 0
  }

  return q + op
}

const getCountryCode = (currency: string | undefined) =>
  currency === 'CAD' ? 'CA' : 'US'

const genLineItems = (lineItems: any[] = []) =>
  lineItems.map(({ node }) => {
    return {
      quantity: node.quantity,
      variantId: node.variant.id
    }
  })

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'init':
      return {
        checkout: action.checkout,
        currency: action.currency
      }

    case 'updateCheckout':
      return {
        ...state,
        checkout: action.checkout
      }

    case 'updateCurrency':
      return {
        checkout: action.checkout,
        currency: action.crrency
      }
  }
}

const iv = {
  checkout: { id: '', lineItems: [] },
  currency: undefined,
  completedAt: null
}

export function ShopifyContextProvider({ children }: { children: ReactNode }) {
  const [{ checkout, currency }, dispatch] = useReducer(reducer, iv)

  const dispatchInit = ({ data = {} }: any) => {
    const { checkout } = data.checkoutCreate || {}

    setItemToLocalStorage('checkoutId', checkout.id)
    setItemToLocalStorage('currency', checkout.currencyCode)

    dispatch({ type: 'init', checkout, currency: checkout.currencyCode })
  }

  const updater =
    (key: string) =>
    ({ data = {} }) => {
      const { checkout } = data[key] || {}
      dispatch({ type: 'updateCheckout', checkout })
    }

  const updateQuantity =
    (op: number) => (variantId: string, lineItemId: string) => () =>
      sendShopifyStorefrontRequest({
        query: updateItem,
        variables: {
          checkoutId: checkout.id,
          lineItems: [
            {
              variantId,
              id: lineItemId,
              quantity: getQuantity(checkout.lineItems, op)(lineItemId)
            }
          ]
        }
      })(updater('checkoutLineItemsUpdate'))

  const initCheckout = (countryCode) =>
    sendShopifyStorefrontRequest({
      query: createCheckout,
      variables: {
        country: countryCode,
        input: {
          buyerIdentity: {
            countryCode
          }
        }
      }
    })(dispatchInit)

  useEffect(() => {
    const checkoutId = getItemFromLocalStorage('checkoutId')
    const currency = getItemFromLocalStorage('currency') || 'CAD'

    if (!currency) {
      setItemToLocalStorage('currency', currency)
    }

    if (!checkoutId) {
      initCheckout(getCountryCode(currency))
    } else {
      sendShopifyStorefrontRequest({
        query: getCheckout,
        variables: { checkoutId, country: getCountryCode(currency) }
      })(({ data }) => {
        if (data.node.completedAt) {
          initCheckout(getCountryCode(currency))
        } else {
          dispatch({
            type: 'init',
            checkout: data.node,
            currency: data.node.currencyCode
          })
        }
      })
    }
  }, [])

  const fns = useMemo(
    () => ({
      add: (variantId: string) => () =>
        sendShopifyStorefrontRequest({
          query: addItem,
          variables: {
            country: checkout.buyerIdentity.countryCode,
            checkoutId: checkout.id,
            lineItems: [{ quantity: 1, variantId }]
          }
        })(updater('checkoutLineItemsAdd')),
      remove: (lineItemId: string) => () => {
        sendShopifyStorefrontRequest({
          query: removeItem,
          variables: {
            country: checkout.buyerIdentity.countryCode,
            checkoutId: checkout.id,
            lineItemIds: [lineItemId]
          }
        })(updater('checkoutLineItemsRemove'))
      }
    }),
    [checkout.id]
  )

  const quantityUpdater = useMemo(
    () => ({
      increase: updateQuantity(1),
      decrease: updateQuantity(-1)
    }),
    [checkout.id, checkout.lineItems]
  )

  const currencyUpdater = useMemo(
    () => ({
      updateCurrency: () => {
        const newCurrency = currency === 'CAD' ? 'USD' : 'CAD'
        const countryCode = getCountryCode(newCurrency)

        setItemToLocalStorage('currency', newCurrency)

        sendShopifyStorefrontRequest({
          query: createCheckout,
          variables: {
            country: countryCode,
            input: {
              buyerIdentity: {
                countryCode
              },
              lineItems: genLineItems(checkout.lineItems.edges)
            }
          }
        })(dispatchInit)
      }
    }),
    [checkout.id, checkout.lineItems, currency]
  )

  return (
    <ShopifyContext.Provider
      value={{
        checkout,
        currency,
        ...fns,
        ...quantityUpdater,
        ...currencyUpdater,
        sendShopifyStorefrontRequest
      }}
    >
      {children}
    </ShopifyContext.Provider>
  )
}
