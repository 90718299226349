export const sendShopifyStorefrontRequest =
  ({ query, variables = {} }) =>
  (f: any) =>
    fetch(
      `https://${process.env.SHOPIFY_STORE_DOMAIN}/api/2021-10/graphql.json`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Shopify-Storefront-Access-Token':
            process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN
        },
        body: JSON.stringify({ query, variables })
      }
    )
      .then((x) => x.json())
      .then(f)
      .catch(console.log)
